import { Injectable } from '@angular/core';
import { InternalUserOrExternalUser } from '../domain';
import { UserKind } from '../domain';

const USER_KEY = 'user';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  clean(): void {
    window.localStorage.clear();
  }

  clearByKey(key: string): void {
    window.localStorage.removeItem(key);
  }

  saveUser(user: InternalUserOrExternalUser): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  getUser(): InternalUserOrExternalUser {
    const user: string | null = window.localStorage.getItem(USER_KEY);
    return user ? JSON.parse(user) : null;
  }

  isLoggedIn(): boolean {
    const user = window.localStorage.getItem(USER_KEY);

    return !!user;
  }

  isInternalUser(): boolean {
    return this.getUser().kind === UserKind.Internal;
  }

  isExternalUser(): boolean {
    return this.getUser().kind === UserKind.External;
  }
}
