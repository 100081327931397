import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { MessageService } from 'primeng/api';
import { Observable, interval, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { RequestResponse, UserWithToken } from '../../domain';
import {
  LoginCredentials,
  RegisterCredentials,
} from '../../domain/auth.domain';
import { StorageService } from '../../helpers/storage.service';
import { baseUrl } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  router: Router;
  private readonly storageService: StorageService;
  private readonly messageService: MessageService;
  private checkInterval = 2 * 60 * 1000; // 2 minutes

  constructor(private http: HttpClient) {
    this.router = inject(Router);
    this.messageService = inject(MessageService);
    this.storageService = inject(StorageService);
    this.startTokenCheck();
  }

  login(credentials: LoginCredentials): Observable<UserWithToken> {
    return this.http
      .post<UserWithToken>(`${baseUrl}/Account/login`, credentials)
      .pipe(
        tap((res: UserWithToken) => {
          const decodedToken: any = jwtDecode(res.accessToken);
          this.setSessionTimeoutToken(decodedToken.exp * 1000);
        })
      );
  }

  logout(): Observable<any> {
    return this.http.post<RequestResponse>(`${baseUrl}/Account/logout`, {});
  }

  logoutWithRedirect(route: string = '/') {
    this.logout()
      .pipe(take(1))
      .subscribe(() => {});
    this.storageService.clean();
    this.clearSessionTimeoutToken();
    this.router.navigate([route]);
  }

  register(
    credentials: RegisterCredentials
  ): Observable<RequestResponse<boolean>> {
    return this.http.post<RequestResponse>(`${baseUrl}/register`, credentials);
  }

  forgotPassword(email: string): Observable<RequestResponse> {
    return this.http.post<RequestResponse>(`${baseUrl}/forgot-password`, {
      email,
    });
  }

  private setSessionTimeoutToken(expiryTime: number): void {
    localStorage.setItem('sessionTimeoutToken', expiryTime.toString());
  }

  private getSessionTimeoutToken(): number | null {
    const token = localStorage.getItem('sessionTimeoutToken');
    return token ? parseInt(token, 10) : null;
  }

  private clearSessionTimeoutToken(): void {
    localStorage.removeItem('sessionTimeoutToken');
  }

  private refreshTokens(): Observable<any> {
    return this.http
      .post(`${baseUrl}/Account/refresh`, {}, { withCredentials: true })
      .pipe(
        tap((response: any) => {
          const decodedToken: any = jwtDecode(response.accessToken);
          this.setSessionTimeoutToken(decodedToken.exp * 1000);

          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Sessão refrescada com sucesso',
          });
        }),
        catchError(error => {
          console.error('Error refreshing tokens:', error);
          this.logoutWithRedirect('/login');

          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Houve um erro ao tentar refrescar a sessão',
          });

          return of(null);
        })
      );
  }

  private startTokenCheck(): void {
    interval(this.checkInterval)
      .pipe(
        switchMap(() => {
          const sessionTimeoutToken = this.getSessionTimeoutToken();
          if (sessionTimeoutToken) {
            const now = Date.now();
            const timeoutThreshold = sessionTimeoutToken - 5 * 60 * 1000; // 5 minute before expiration

            if (now > timeoutThreshold) {
              return this.refreshTokens();
            }
          }
          return of(null);
        })
      )
      .subscribe();
  }

  isLoggedIn(): boolean {
    const token = this.getSessionTimeoutToken();
    if (token === null) return false;
    const currentTime = Date.now();
    return currentTime < token;
  }
}
