import { CompanyLinkageKind } from './company.domain';

export enum UserRole {
  None = 'None',
  Viewer = 'Viewer',
  Reviewer = 'Reviewer',
  ReviewDelegator = 'ReviewDelegator',
  Approver = 'Approver',
  ApproveDelegator = 'ApproveDelegator',
  Homologator = 'Homologator',
}

export enum UserKind {
  External = 'External',
  Internal = 'Internal',
}

export type InternalUserOrExternalUser = InternalUser | ExternalUser;

export interface ExternalUser {
  id?: number;
  name?: string;
  username?: string;
  password?: string;
  title?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  kind?: string;
  countryCode?: string;
  companyID?: number;
  linkageKind: CompanyLinkageKind;
  numberOfShares?: number;
  percentageOfShares?: number;
}

export interface ExternalUserDto extends ExternalUser {}

export interface InternalUser {
  id?: number;
  name?: string;
  username?: string;
  password?: string;
  roleCode?: string;
  title?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  kind?: string;
  departmentCode?: string;
  permissions: string[];
}

export interface InternalUserDto {
  id?: number;
  name?: string;
  username?: string;
  password?: string;
  title?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  kind: UserKind;
  roleCode?: string;
  permissions?: string[];
  departmentCode: string;
  departmentName?: string;
}

export interface User {
  id?: number;
  name?: string;
  kind: UserKind;
  username?: string;
  role: UserRole;
  title?: string;
  email?: string;
  mobile?: string;
  phone?: string;
  fax?: string;
  lastLogin: string;
}

export interface UserLoginDto {
  id?: number;
  name?: string;
  kind: UserKind;
  username?: string;
  password?: string;
  title?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  countryCode?: string;
  companyID?: number;
  linkageKind: CompanyLinkageKind;
  numberOfShares?: number;
  percentageOfShares?: number;
  roleCode?: string;
  permissions?: string[];
  departmentCode?: string;
}

export interface UserWithToken {
  user: UserLoginDto;
  accessToken: string;
}

export enum UserPermissions {
  None = 'None',
  CanLogin = 'CanLogin',
  CanViewOwnAccount = 'CanViewOwnAccount',
  CanRecoverOwnAccount = 'CanRecoverOwnAccount',
  CanUpdateOwnAccount = 'CanUpdateOwnAccount',
  CanViewGenerics = 'CanViewGenerics',
  CanCreateGenerics = 'CanCreateGenerics',
  CanUpdateGenerics = 'CanUpdateGenerics',
  CanDeleteGenerics = 'CanDeleteGenerics',
  CanManageGenerics = 'CanManageGenerics',
  CanViewOwnCompany = 'CanViewOwnCompany',
  CanCreateOwnCompany = 'CanCreateOwnCompany',
  CanUpdateOwnCompany = 'CanUpdateOwnCompany',
  CanDeleteOwnCompany = 'CanDeleteOwnCompany',
  CanManageOwnCompany = 'CanManageOwnCompany',
  CanViewAnyCompany = 'CanViewAnyCompany',
  CanCreateAnyCompany = 'CanCreateAnyCompany',
  CanUpdateAnyCompany = 'CanUpdateAnyCompany',
  CanDeleteAnyCompany = 'CanDeleteAnyCompany',
  CanManageAnyCompany = 'CanManageAnyCompany',
  CanViewExternalUsers = 'CanViewExternalUsers',
  CanViewAllExternalUsers = 'CanViewAllExternalUsers',
  CanCreateExternalUsers = 'CanCreateExternalUsers',
  CanRecoverExternalUserAccounts = 'CanRecoverExternalUserAccounts',
  CanUpdateExternalUsers = 'CanUpdateExternalUsers',
  CanDeleteExternalUsers = 'CanDeleteExternalUsers',
  CanManageExternalUsers = 'CanManageExternalUsers',
  CanViewInternalUsers = 'CanViewInternalUsers',
  CanViewAllInternalUsers = 'CanViewAllInternalUsers',
  CanCreateInternalUsers = 'CanCreateInternalUsers',
  CanRecoverInternalUserAccounts = 'CanRecoverInternalUserAccounts',
  CanUpdateInternalUsers = 'CanUpdateInternalUsers',
  CanDeleteInternalUsers = 'CanDeleteInternalUsers',
  CanManageInternalUsers = 'CanManageInternalUsers',
  CanViewOwnMessage = 'CanViewOwnMessage',
  CanCreateOwnMessage = 'CanCreateOwnMessage',
  CanUpdateOwnMessage = 'CanUpdateOwnMessage',
  CanDeleteOwnMessage = 'CanDeleteOwnMessage',
  CanManageOwnMessage = 'CanManageOwnMessage',
  CanViewAnyMessage = 'CanViewAnyMessage',
  CanDeleteAnyMessage = 'CanDeleteAnyMessage',
  CanManageAnyMessage = 'CanManageAnyMessage',
  CanViewProcess = 'CanViewProcess',
  CanCreateProcess = 'CanCreateProcess',
  CanUpdateProcess = 'CanUpdateProcess',
  CanDeleteProcess = 'CanDeleteProcess',
  CanManageProcess = 'CanManageProcess',
  CanViewAnyProcess = 'CanViewAnyProcess',
  CanUpdateAnyProcess = 'CanUpdateAnyProcess',
  CanDeleteAnyProcess = 'CanDeleteAnyProcess',
  CanManageAnyProcess = 'CanManageAnyProcess',
  CanReviewProcess = 'CanReviewProcess',
  CanReviewAnyProcess = 'CanReviewAnyProcess',
  CanApproveAnyProcess = 'CanApproveAnyProcess',
  CanAssignAnyProcess = 'CanAssignAnyProcess',
  CanHomologateAnyProcess = 'CanHomologateAnyProcess',
  _Launcher_ = '_Launcher_',
  _System_ = '_System_',
}
